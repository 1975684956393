import React from "react"
import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"
import Text from "@src/components/Text"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import track from "@src/utils/track"

import { ReactComponent as GoogleSheetsIcon } from "@src/icons/v2/google-sheets-icon.svg"
import { ReactComponent as AsanaIcon } from "@src/icons/v2/asana-icon.svg"
import { ReactComponent as TwitterIntegrationsIcon } from "@src/icons/v2/twitter-integrations.svg"
import { ReactComponent as GithubIntegrations } from "@src/icons/v2/github-integrations.svg"
import { ReactComponent as YoutubeIntegration } from "@src/icons/v2/youtube-integrations.svg"
import { ReactComponent as StripeIntegration } from "@src/icons/v2/stripe-integrations.svg"
import { ReactComponent as SlackIntegration } from "@src/icons/v2/slack-integrations.svg"
import TwitterBlog from "@src/icons/v2/twitter-blog.png"

import * as styles from "./variation.module.scss"

const TwitterIntegrations = ({ location }) => {
  const mainAppUrl = getAppUrl(location, "/dashboard/marketplace/integrations")

  return (
    <Layout
      title="Tap into the core Twitter elements with Twitter integrations"
      description="Programmatically access Twitter in unique and advanced ways. Utilize core elements such as Tweets, Direct Messages, Spaces, Lists, users, and others"
      url="https://canonic.dev/features/twitter-integrations"
      location={location}
    >
      <SplashWithIllustration
        isWhite
        illustrationComponent={<TwitterIntegrationsIcon />}
        className={styles.SocialIntegrations}
        heading="Twitter Integration"
        title="Tap into the core Twitter elements with Twitter integration"
        subtitle="Programmatically access Twitter in unique and advanced ways. Utilize core elements such as Tweets, Direct Messages, Spaces, Lists, users, and others."
        buttons={[
          {
            title: "Clone & get started →",
            isPrimary: true,
            href: `${mainAppUrl}`,
            onClick: () => track("Start for free", location.pathname),
          },
        ]}
      />
      <Section blueGradient className={styles.SocialIntegrationsActions}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Features
        </Text.Heading>
        <Text.H2 white>Few of the Triggers and Actions</Text.H2>

        <FeatureGrid cards className={styles.SocialIntegrationsActionsCards}>
          {[
            {
              title: "View Tweets",
              description: `Perform actions such as - Tweets lookup, Tweet count, Retweets count, Like and many more.`,
            },

            {
              title: "Handle Users",
              description: `Perform actions such as - users lookup, follow loopkup, blocks loopkup, mutes loopkup.`,
            },
            {
              title: "Manage Spaces",
              description: `Supports actions such as - spaces overview, spaces lookup, search spaces and many more.`,
            },
            {
              title: "Manage Lists",
              description: `The integration can - lookup lists, manage lists, list members, list follows, pinned lists.`,
            },
          ]}
        </FeatureGrid>
      </Section>

      <Section white lassName={styles.SocialIntegrationsSamples}>
        <div className={styles.SocialIntegrationsSamplesWrapper}>
          <div className={styles.SocialIntegrationsSamplesWrapperFirst}>
            <Text.Heading>Sample Project</Text.Heading>
            <Text.H2 lightBlueGradient>
              Clone the project built with GitHub integration or read the guide
              on how to build it
            </Text.H2>
            <FeatureGrid cards white>
              {[
                {
                  title:
                    "How to build a marketing dashboard with React and react-chartsjs",
                  description:
                    "Want to monitor data from multiple social channels? We’ve got you covered. Get all your data in 1 dashboard and customize it as per your needs",
                  link: {
                    title: "Clone Project",
                    url: "https://app.canonic.dev/projects/61b4e0b47bfc8f00268a7f9e/graph",
                  },
                },
              ]}
            </FeatureGrid>
          </div>
          <div className={styles.SocialIntegrationsSamplesWrapperSecond}>
            <div className={styles.blogCards}>
              <div>
                <figure className={styles.blogCardsFigure}>
                  <img src={TwitterBlog} />
                </figure>
                <Text.H3>
                  How to build a marketing dashboard (twitter & youtube) with
                  React
                </Text.H3>

                <Text.P>
                  Want to monitor data from multiple social channels? We’ve got
                  you covered. Get all your data in 1 dashboard and customize it
                  as per your needs
                </Text.P>
                <a
                  href={
                    "https://dev.to/canonic/how-to-build-a-twitter-youtube-analytics-dashboard-with-react-2p2d"
                  }
                >
                  <button>Step by Step Guide </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section lightBlue className={styles.SocialIntegrationsApp}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>Pick an App to pair with</Text.H2>
        <FeatureGrid cards className={styles.SocialIntegrationsAppCards}>
          {[
            {
              icon: GoogleSheetsIcon,
              title: "Google Sheets",
              description: `Spreadsheets  |  Google `,
            },
            {
              icon: AsanaIcon,
              title: "Asana",
              description: `Project Management`,
            },
            {
              icon: GithubIntegrations,
              title: "GitHub",
              description: `Version Management`,
            },
            {
              icon: YoutubeIntegration,
              title: "Youtube",
              description: `Entertainment`,
            },
            {
              icon: StripeIntegration,
              title: "Stripe",
              description: `Payment Gateway`,
            },
            {
              icon: SlackIntegration,
              title: "Slack",
              description: `Business Communication`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Section black className={styles.SocialIntegrationsBenefits}>
        <Text.Heading className={styles.SocialIntegrationsH4}>
          Benefits
        </Text.Heading>
        <Text.H2 lightBlueGradient>
          Here’s why you should integrate using Canonic
        </Text.H2>
        <FeatureGrid
          vertical
          fullWidth
          className={styles.SocialIntegrationsBenefitsCards}
        >
          {[
            {
              //   icon: PlaceholderIcon,
              title: "Plenty of Inegrations",
              description: `A good deal of full featured set of integrations.`,
            },
            {
              //   icon: PlaceholderIcon,
              title: "Chanining Multiple Integration",
              description: `Multiple integrations can be chained to one another.`,
            },
            {
              //   icon: PlaceholderIcon,
              title: "Test your Data",
              description: `Test the data you are receiving on the GraphQL Playground.`,
            },
          ]}
        </FeatureGrid>
      </Section>
      <Callout location={location} title="twitterIntegrations" />
      <CommunityCallout />
    </Layout>
  )
}

export default TwitterIntegrations
